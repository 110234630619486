import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FiFileText, FiDownload, FiExternalLink } from 'react-icons/fi';

const ResumeButton = () => {
  const [showModal, setShowModal] = React.useState(false);

  const handleClose = () => setShowModal(false);
  const handleOpen = () => setShowModal(true);

  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered size="lg" dialogClassName="modal-no-border">
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <iframe
            title="Resume"
            src="https://drive.google.com/file/d/1tSuqIZXaHJgDhEqcSbaCcSZi97PZFBZx/preview"
            width="100%"
            height="400"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            href="https://drive.google.com/file/d/1tSuqIZXaHJgDhEqcSbaCcSZi97PZFBZx/view"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <FiExternalLink /> Open
          </Button>
          <Button
            variant="outline-success"
            href="https://drive.google.com/uc?export=download&id=1tSuqIZXaHJgDhEqcSbaCcSZi97PZFBZx"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <FiDownload /> Download
          </Button>
        </Modal.Footer>
      </Modal>

      <Button variant="primary" onClick={handleOpen} className="home-button mb-3">
        <FiFileText /> Resume
      </Button>
    </>
  );
};

export default ResumeButton;
