import { SiPython, SiCplusplus, SiJavascript, SiPhp, SiPostgresql, } from "react-icons/si"
import { SiReact, SiNodedotjs, SiExpress, SiFlask, SiNextdotjs, SiMongodb, SiFirebase, SiMysql, SiBootstrap, SiTailwindcss} from "react-icons/si"
import { SiGit, SiAndroidstudio, SiPostman, SiAnaconda } from "react-icons/si"
import { SiLinux, SiMicrosoftedge, SiWindows, SiGithub, SiGitlab } from "react-icons/si"


import { MdLeaderboard, MdLightbulb } from "react-icons/md"
import { RiTeamFill } from "react-icons/ri"
import { FaBrain, FaBriefcase } from "react-icons/fa"
export const programming_languages = [
    {
        id: 1,
        text: "Python",
        icon: <SiPython />,
        color: "#336D9D"
    },
    {
        id: 2,
        text: "C/C++",
        icon: <SiCplusplus />,
        color: "#00417B"
    },
    {
        id: 3,
        text: "JavaScript",
        icon: <SiJavascript />,
        color: "#EAD41C"
    },
    {
        id: 4,
        text: "PHP",
        icon: <SiPhp />,
        color: "#7377AD"
    },
    {
        id: 5,
        text: "SQL",
        icon: <SiPostgresql />,
        color: "#30628A"
    }
]

export const technologies_frameworks = [
    {
        id: 1,
        text: "React.js",
        icon: <SiReact />
    },
    {
        id: 2,
        text: "Node.js",
        icon: <SiNodedotjs />
    },
    {
        id: 3,
        text: "Express.js",
        icon: <SiExpress />
    },
    {
        id: 9,
        text: "Flask",
        icon: <SiFlask />
    },
    {
        id: 10,
        text: "Next.js",
        icon: <SiNextdotjs />
    },
    {
        id: 4,
        text: "MongoDB",
        icon: <SiMongodb />
    },
    {
        id: 5,
        text: "Firebase",
        icon: <SiFirebase />
    },
    {
        id: 6,
        text: "MySQL",
        icon: <SiMysql />
    },
    {
        id: 7,
        text: "Bootstrap",
        icon: <SiBootstrap />
    },
    {
        id: 8,
        text: "Tailwind CSS",
        icon: <SiTailwindcss />
    },
]

export const developer_tools = [
    {
        id: 1,
        text: "Git",
        icon: <SiGit />
    },
    {
        id: 2,
        text: "GitHub",
        icon: <SiGithub />
    },
    {
        id: 3,
        text: "GitLab",
        icon: <SiGitlab />
    },
    {
        id: 4,
        text: "Android Studio",
        icon: <SiAndroidstudio />
    },
    {
        id: 5,
        text: "Postman",
        icon: <SiPostman />
    },
    {
        id: 6,
        text: "Anaconda",
        icon: <SiAnaconda />
    }
]

export const platforms = [
    {
        id: 1,
        text: "Linux",
        icon: <SiLinux />
    },
    {
        id: 2,
        text: "Web",
        icon: <SiMicrosoftedge />
    },
    {
        id: 3,
        text: "Windows",
        icon: <SiWindows />
    }
]

export const soft_skills = [
    {
        id: 1,
        text: "Organizational Leadership",
        icon: <MdLeaderboard />
    },
    {
        id: 2,
        text: "Analytical Thinking",
        icon: <MdLightbulb />
    },
    {
        id: 3,
        text: "Teamwork",
        icon: <RiTeamFill />
    },
    {
        id: 4,
        text: "Problem Solving",
        icon: <FaBrain />
    },
    {
        id: 5,
        text: "Event Management",
        icon: <FaBriefcase />
    }
]