export const projects = [
    {
        id: 0,
        title: "Personal Blogs",
        subtitle: "Discover insightful articles and engaging content on my personal blogs, where knowledge meets creativity.",
        link:"https://blog-website-qowu.onrender.com/",
        repo:"https://github.com/Utkarshsaini01/Blog-Website",
        emoji:"🖋️"
    },
    {
        id: 1,
        title: "DigitalDose",
        subtitle: "An app providing quick medical test results via API, appointment booking, and seamless data sharing with doctors.",
        link:"",
        repo:"https://github.com/Utkarshsaini01/Digidose",
        emoji:"📅"
    },
    {
        id: 2,
        title: "Flight Finder",
        subtitle: "Designed a travel group integration system where users can opt-in for spontaneous trips.",
        link: "",
        repo: "https://github.com/Utkarshsaini01/Flight-Finder",
        emoji: "🛫"
    },
    {
        id: 3,
        title: "Cloud Based Secured Medical Document Storage",
        subtitle: "The backend infrastructure for a cloud-based secured medical document storage system.",
        link: "",
        repo: "https://github.com/Utkarshsaini01/MedDoc-Backend",
        emoji: "🔒"
    },
    {
        id: 4,
        title: "BookWise",
        subtitle: "Backend for library management system with user-friendly APIs",
        link: "",
        repo: "https://github.com/Utkarshsaini01/Library-Management-Backend",
        emoji: "📚"
    },
    {
        id: 5,
        title: "Doc Hub",
        subtitle: "Connecting your files, everywhere you go.",
        link: "https://doc-hub-utkarshsaini01s-projects.vercel.app/",
        repo: "https://github.com/Utkarshsaini01/DocHub",
        emoji: "📦"
    },
    {
        id: 6,
        title: "Ahead",
        subtitle: "Revamped web application's frontend design inspired by Dribbble's aesthetic.",
        link: "https://ahead-density-exchange-utkarshsaini01s-projects.vercel.app/",
        repo: "https://github.com/Utkarshsaini01/Ahead-Dribble",
        emoji: "🎨"
    },
    {
        id: 7,
        title: "Malware Detection using ML and Deep Learning Techniques",
        subtitle: "Deployed multiple ML/DL models to detect malware, assessing accuracy, F1 scores, and confusion matrices.",
        link: "",
        repo: "https://github.com/Utkarshsaini01/Malware-Detection-using-ML-and-Deep-Learning-Techniques",
        emoji: "🛡️"
    },
    {
        id: 8,
        title: "Python Begineer Projects",
        subtitle: "Explore a collection of beginner-friendly Python projects on my GitHub repository!",
        link: "",
        repo: "https://github.com/Utkarshsaini01/Python-Beginner-Projects/tree/main",
        emoji: "🐍"
    },
    
]