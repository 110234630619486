// importing certificates
import DSA from "../images/certificates/DSA.jpg"
import GCC from "../images/certificates/gcc.png"
import Python from "../images/certificates/python.jpg"
import WebDev from "../images/certificates/webdev.jpg"

const certifications = [
    {
        id: 0,
        title: "Google Cloud Computing Foundation Program",
        description: "Google And VIT",
        certificate: GCC,
        date: "Oct 2022",
        link: "https://www.cloudskillsboost.google/public_profiles/c83e2d48-74ab-4f31-946e-033a9de082e9"
    },
    {
        id: 1,
        title: "The Complete 2022 Web Development Bootcamp",
        description: "Udemy",
        certificate: WebDev,
        date: "Oct 2022",
        link: "https://www.udemy.com/certificate/UC-a33ad3ed-68c6-4aba-b39b-9dc7d6117685/"
    },
    {
        id: 2,
        title: "Mastering Data Structures and Algorithms using C and C++",
        description: "Udemy",
        certificate: DSA,
        date: "Jun 2023",
        link: "https://www.udemy.com/certificate/UC-312c950d-f7ec-483b-941a-a255ea49c887/"
    },
    {
        id: 3,
        title: "100 Days of Code: The Complete Python Pro Bootcamp",
        description: "Udemy",
        certificate: Python,
        date: "Mar 2024",
        link: "https://www.udemy.com/certificate/UC-52434dc3-98f8-4d80-a1d5-cecf8562f752/"
    },

]

export default certifications;
