import React from 'react';

const Footer = () => {
  return (
    <footer className="footer fixed-bottom">
      <div className="container">
        <div className="row">
          <div className="col">
            <p className="text-center">
              Copyright © {new Date().getFullYear()}{" "}
              <a 
                href="https://sainiutkarsh.com" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="footer-link"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                Utkarsh Saini
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
