import React from "react";

const Loader = () => {

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 p-4">
      <style>
        {`
            #logo{
                animation: fill 0.5s ease forwards 3s;
            }
              
              
            #logo path:nth-child(1){
                stroke-dasharray: 512.8551025390625;
                stroke-dashoffset: 512.8551025390625;
                animation: line-anim 2s ease forwards 1.8s;
            }
              
            #logo path:nth-child(2){
                stroke-dasharray: 371.0201721191406;
                stroke-dashoffset: 371.0201721191406;
                animation: line-anim 2s ease forwards 1.5s;
            }
            
            #logo path:nth-child(3){
                stroke-dasharray: 456.3013000488281;
                stroke-dashoffset: 456.3013000488281;
                animation: line-anim 2s ease forwards 1.2s;
            }

            #logo path:nth-child(4){
                stroke-dasharray: 418.56182861328125;
                stroke-dashoffset: 418.56182861328125;
                animation: line-anim 2s ease forwards 0.9s;
            }

            #logo path:nth-child(5){
                stroke-dasharray: 481.97833251953125;
                stroke-dashoffset: 481.97833251953125;
                animation: line-anim 2s ease forwards 0.6s;
            }

            #logo path:nth-child(6){
                stroke-dasharray: 365.0877990722656;
                stroke-dashoffset: 365.0877990722656;
                animation: line-anim 2s ease forwards 0.3s;
            }

            #logo path:nth-child(7){
                stroke-dasharray: 415.4775695800781;
                stroke-dashoffset: 415.4775695800781;
                animation: line-anim 2s ease forwards;
            }
              
              
            @keyframes line-anim {
                to{
                    stroke-dashoffset: 0;
                }
            }
              
            @keyframes fill {
                to{
                  fill: #1a222bdb;
                }
            }

          `}

      </style>

      <svg
        id="logo"
        width="516"
        height="85"
        viewBox="0 0 516 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M509.265 81.5447H510.221L510.625 80.6781L512.36 76.9538L513.048 75.4759L511.518 74.9127L503.393 71.9222V17.3395L511.095 10.8992L511.633 10.4495V9.74849V6.86216V5.36216H510.133H483.765H482.809L482.405 6.22882L480.67 9.95312L479.978 11.4399L481.52 11.9972L489.55 14.8982V39.9982H458.289V17.3395L465.992 10.8992L466.529 10.4495V9.74849V6.86216V5.36216H465.029H438.661H437.705L437.302 6.22881L435.567 9.95311L434.874 11.4399L436.417 11.9972L444.447 14.8982V69.5721L436.826 76.0127L436.294 76.4621V77.1584V80.0447V81.5447H437.794H464.162H465.118L465.522 80.6781L467.256 76.9538L467.945 75.4759L466.415 74.9127L458.289 71.9222V47.188H489.55V69.5721L481.929 76.0127L481.397 76.4621V77.1584V80.0447V81.5447H482.897H509.265Z"
          stroke="#212121"
          stroke-width="3"
        />
        <path
          d="M406.933 54.8499L406.954 54.8634L406.974 54.8762C409.064 56.1748 410.763 57.6007 412.095 59.1442C413.343 60.5912 413.899 62.0536 413.899 63.5647C413.899 65.3585 413.472 67.071 412.603 68.7207C411.754 70.2709 410.532 71.5366 408.899 72.5224C407.305 73.485 405.467 73.9825 403.342 73.9825C399.728 73.9825 396.161 72.7242 392.614 70.0887C389.052 67.441 385.823 63.6975 382.952 58.8022L382.517 58.0611H381.658H378.362H376.862V59.5611V77.6239V78.5781L377.726 78.9826C380.168 80.1251 384.031 81.1448 389.197 82.0794L389.2 82.0799C394.46 83.0209 399.034 83.5 402.909 83.5C406.368 83.5 410.036 82.1373 413.878 79.6241L413.884 79.6204C417.786 77.0425 421.097 73.9103 423.806 70.2261L423.814 70.216C426.524 66.4664 428.001 62.8106 428.001 59.2817C428.001 56.025 426.961 53.0925 424.907 50.5363C423.008 48.0416 420.66 45.8861 417.878 44.0666C415.164 42.2307 411.653 40.1599 407.36 37.8554C403.504 35.693 400.497 33.9404 398.331 32.5941C396.313 31.3053 394.613 29.8548 393.219 28.2477C391.971 26.6849 391.398 25.0914 391.398 23.4353C391.398 20.1768 392.27 17.7007 393.887 15.8597C395.536 13.9861 397.737 13.0175 400.654 13.0175C403.943 13.0175 407.141 14.249 410.276 16.86L410.276 16.86L410.285 16.868C413.516 19.513 416.528 23.2623 419.296 28.1754L419.727 28.9389H420.603H423.899H425.399V27.4389V9.37606V8.4324L424.548 8.02389C422.173 6.88305 418.549 5.86129 413.78 4.92472C408.973 3.98094 404.709 3.5 401.001 3.5C397.63 3.5 394.093 4.91472 390.425 7.49593C386.803 10.0237 383.727 13.1659 381.199 16.9119C378.664 20.6053 377.296 24.2173 377.296 27.7183C377.296 30.9802 378.339 33.9665 380.38 36.6364L380.386 36.6453L380.393 36.6541C382.401 39.2012 384.8 41.4161 387.583 43.2991L387.593 43.3059L387.603 43.3125C390.301 45.0753 393.859 47.2034 398.268 49.6941L398.268 49.6942L398.279 49.7005C401.964 51.7398 404.843 53.4551 406.933 54.8499Z"
          stroke="#212121"
          stroke-width="3"
        />
        <path
          d="M370.244 81.7309H371.2L371.604 80.8643L373.339 77.14L373.939 75.8507L372.684 75.1825L362.741 69.891L349.512 46.9862L349.239 46.5131L348.725 46.3266L346.817 45.6333C348.83 44.7053 350.77 43.5792 352.636 42.2566L352.642 42.2525C355.731 40.0417 358.24 37.4548 360.144 34.4873L360.148 34.481L360.152 34.4747C362.081 31.4019 363.07 28.1488 363.07 24.7388C363.07 21.0703 362.167 17.6755 360.354 14.5851C358.59 11.4777 356.042 9.02367 352.754 7.22499C349.499 5.41157 345.803 4.52419 341.708 4.52419C339.862 4.52419 337.074 4.65129 333.367 4.89996L333.358 4.90062C328.307 5.2703 323.168 5.45527 317.942 5.45527H307.88H306.924L306.521 6.32192L304.786 10.0462L304.087 11.5468L305.649 12.0949L313.666 14.9085V69.7584L306.045 76.1989L305.513 76.6483V77.3446V80.2309V81.7309H307.013H333.381H334.337L334.741 80.8643L336.475 77.14L337.164 75.6621L335.634 75.099L327.508 72.1085V15.9177C329.811 14.3442 332.279 13.5762 334.942 13.5762C339.423 13.5762 342.648 15.0704 344.858 17.9285L344.858 17.9286L344.866 17.9395C347.164 20.8541 348.361 24.5738 348.361 29.208C348.361 32.5959 347.323 35.1196 345.365 36.959C343.458 38.7433 340.769 39.7189 337.111 39.7189H332.253H331.297L330.894 40.5855L329.593 43.3787L329.087 44.4631L330.032 45.1968L333.877 48.1822L352.812 80.9809L353.245 81.7309H354.111H370.244Z"
          stroke="#212121"
          stroke-width="3"
        />
        <path
          d="M299.977 81.5447H300.933L301.337 80.6781L303.071 76.9538L303.62 75.7768L302.533 75.0655L294.263 69.6502L269.11 4.55247L268.503 2.98107L267.012 3.76578L257.991 8.51427L257.507 8.76947L257.3 9.27707L232.893 69.3614L225.022 76.0127L224.49 76.4621V77.1584V80.0447V81.5447H225.99H252.358H253.314L253.718 80.6781L255.453 76.9538L256.143 75.4731L254.609 74.9118L240.799 69.8583L246.951 54.6366H274.056L279.974 69.8993L272.648 76.0062L272.109 76.456V77.1584V80.0447V81.5447H273.609H299.977ZM271.332 47.4468H249.897L260.831 20.345L271.332 47.4468Z"
          stroke="#212121"
          stroke-width="3"
        />
        <path
          d="M186.293 76.9538L186.981 75.4759L185.451 74.9127L177.326 71.9222V17.3395L185.028 10.8992L185.566 10.4495V9.74849V6.86216V5.36216H184.066H157.698H156.742L156.338 6.22881L154.603 9.95311L153.911 11.4399L155.453 11.9972L163.484 14.8982V69.5721L155.862 76.0127L155.33 76.4621V77.1584V80.0447V81.5447H156.83H183.198H184.155L184.558 80.6781L186.293 76.9538ZM223.358 81.5447H224.314L224.717 80.6781L226.452 76.9538L227.055 75.6597L225.792 74.9937L216.031 69.845L193.182 39.1632L223.429 10.8435L223.904 10.3989V9.74849V6.86216V5.36216H222.404H199.071H198.115L197.712 6.22881L195.977 9.95311L195.472 11.037L196.416 11.7707L205.091 18.5139L179.744 42.2658L178.774 43.1748L179.56 44.2471L206.449 80.9315L206.898 81.5447H207.658H223.358Z"
          stroke="#212121"
          stroke-width="3"
        />
        <path
          d="M146.057 29.8754L147.052 29.5195V28.4631V5.18621V3.05635L145.047 3.7739L140.608 5.36215H90.474L86.0353 3.7739L84.0299 3.05635V5.18621V28.4631V29.5195L85.0246 29.8754L87.6267 30.8065L88.7606 31.2122L89.4018 30.1928L100.497 12.552H108.49V69.5721L100.868 76.0127L100.336 76.4621V77.1584V80.0447V81.5447H101.836H128.205H129.161L129.564 80.6781L131.299 76.9538L131.987 75.4758L130.457 74.9127L122.332 71.9222V12.552H130.585L141.68 30.1928L142.321 31.2122L143.455 30.8065L146.057 29.8754Z"
          stroke="#212121"
          stroke-width="3"
        />
        <path
          d="M54.1086 79.7836L54.116 79.7796L54.1234 79.7755C58.6521 77.2791 62.416 73.5816 65.4255 68.7375C68.5501 63.8024 70.0782 57.9278 70.0782 51.1814V17.3395L77.7804 10.8992L78.3183 10.4495V9.74849V6.86216V5.36216H76.8183H56.5218H55.5657L55.1621 6.22881L53.4273 9.95311L52.8289 11.2379L54.0777 11.9081L62.3077 16.3254V49.971C62.3077 54.7637 61.4707 58.8493 59.85 62.2708L59.8458 62.2796L59.8418 62.2884C58.2491 65.7667 56.2302 68.3336 53.8242 70.0751C51.4313 71.7867 49.0715 72.5858 46.7205 72.5858C39.6892 72.5858 34.681 70.4899 31.4002 66.5239C28.1061 62.4716 26.3628 56.7074 26.3628 49.0399V17.3395L34.065 10.8992L34.6028 10.4495V9.74849V6.86216V5.36216H33.1028H6.73474H5.77869L5.37501 6.22881L3.64027 9.95311L2.94772 11.4399L4.49035 11.9972L12.5207 14.8982V53.5091C12.5207 59.1246 13.695 64.2381 16.072 68.818L16.0777 68.8289L16.0836 68.8397C18.5188 73.3491 21.8814 76.9297 26.1602 79.5543L26.1713 79.5611L26.1826 79.5678C30.5373 82.1355 35.4301 83.4069 40.8224 83.4069C45.2171 83.4069 49.6513 82.1759 54.1086 79.7836Z"
          stroke="#212121"
          stroke-width="3"
        />
      </svg>
    </div>
  );
};

export default Loader;
