import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import { FiHome } from "react-icons/fi";
import Footer from "../components/footer";

const PageNotFound = () => {
  return (
    <div>
      <div
        className="navbar-container mt-2 ms-4"
        style={{ fontSize: "1.5rem" }}
      >
        <NavBar />
      </div>
      <div className="container d-flex flex-column justify-content-center align-items-center vh-100">
        <div className="text-center">
          <h2 className="display-4">404 | Not Found</h2>
          <p className="lead">The page you're looking for was not found.</p>
          <Link
            to="/"
            className="btn btn-outline-secondary btn-lg"
            role="button"
          >
            <FiHome className="me-2" /> Return To Home
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PageNotFound;
