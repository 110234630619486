import React, { useEffect, useContext } from 'react';
import NavBar from "../components/NavBar";
import Project from '../components/Project/Project';
import { projects } from "../Data/projects";
import Loader from "../components/Loader/Loader";
import loaderContext from "../context/loaderContext";

const Projects = () => {
    const context = useContext(loaderContext);
    const { loading, setLoading } = context;

    useEffect(() => {
        document.title = "Projects | Utkarsh Saini";
        setTimeout(() => {
            setLoading(false);
        }, 4000);
    }, [setLoading]);

    return (
        <div className="container-fluid d-flex flex-column align-items-stretch" style={{ padding: "1rem" }}>
            {loading ? <Loader /> : (
                <>
                    <div className="navbar-container mt-2 ms-4" style={{ fontSize: "1.5rem" }}>
                        <NavBar />
                    </div>
                    <div className="container mt-4 p-8">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-8 col-sm-12">
                                <h2 className="text-left">Projects</h2>
                                <div className="text-justify">
                                    {projects.map((project, i) => (
                                        <div key={project.id} className="mb-2">
                                            <Project
                                                key={i}
                                                name={project.title}
                                                tech={project.tech}
                                                description={project.subtitle}
                                                emoji={project.emoji}
                                                github={project.repo}
                                                website={project.link}
                                                delay={(i + 1) / 6}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Projects;
