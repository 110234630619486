import React from 'react';
import { motion } from 'framer-motion';
import { FiCalendar, FiPercent } from 'react-icons/fi';

const EducationItem = (props) => {
    return (
        <motion.div
            initial={{ y: '100%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
                type: 'spring',
                duration: props.delay,
            }}
        >
            <motion.div whileHover={{ scale: 1.05 }}>
                <div className="row">
                    <div className="col-lg-9 col-md-12 col-sm-12">
                        <div className="p-3 border rounded d-flex align-items-start">
                            <img
                                className="img-fluid rounded mr-4 mt-1"
                                src={props.logo}
                                alt="Logo"
                                style={{ maxHeight: '70px', maxWidth: '70px' }}
                            />
                            <div className='d-flex flex-column mx-4'>
                                <p className="fw-bold mb-0">{props.name}</p>
                                <p className="mb-0">
                                    {props.degree} · {props.stream}
                                </p>
                                <p className="text-muted mb-0">
                                    <FiCalendar /> {props.date}
                                </p>
                                <p className="text-muted mb-0">
                                    <FiPercent /> Grade: {props.grade}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default EducationItem;
