import React, { useEffect, useContext } from "react";
import NavBar from "../components/NavBar.js";
import Footer from "../components/footer.js";
import Image from "../components/Home/Image.js";
import SocialMedia from "../components/Home/SocialMedia.js";
import TextAnimation from "../components/Home/TextTransition.js";
import ContactButton from "../components/Home/contactbutton.js";
import ResumeButton from "../components/Home/resumeButton.js";
import Loader from "../components/Loader/Loader.js";
import loaderContext from '../context/loaderContext';

function Home() {
  const context = useContext(loaderContext);
  const { loading, setLoading } = context;

  useEffect(() => {
    document.title = "Utkarsh Saini";
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, [setLoading]);

  return (
    <div className="App">
      {loading && <Loader />} {/* Loader outside of content */}
      {!loading && (
        <>
          <div className="navbar-container mt-2 ms-4" style={{ fontSize: "1.5rem" }}>
            <NavBar />
          </div>
          <div className="content-wrapper d-flex justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6"> {/* Changed col-md-6 */}
                  <div className="px-4 py-5 text-center">
                    <div className="col-6 mx-auto">
                      <Image />
                    </div>
                    <div>
                      <h1 className="display-5 fw-bolder">Utkarsh Saini</h1>
                    </div>
                    <div className="col-lg-6 mx-auto">
                      <TextAnimation />
                    </div>
                    <div className="mx-auto" style={{ maxWidth: "288px" }}>
                      <SocialMedia />
                    </div>

                    <div className="my-3 mx-auto d-flex flex-wrap justify-content-center" style={{ maxWidth: "288px" }}>
                      <div className="flex-grow-1">
                        <ContactButton />
                      </div>
                      <div className="flex-grow-1">
                        <ResumeButton />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Home;
