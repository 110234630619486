import PadhaiHelp from "../images/padhai help logo.png"
import CodeClause from "../images/CodeClause.png"

export const experience = [
    {
        id: 2,
        role: "Web Development",
        company: "CodeClause",
        type: "Internship",
        location: "Remote",
        date: "Jul 2023 - Jul 2023",
        logo: CodeClause,
        description: [
            
        ]
    },
    {
        id: 1,
        role: "Full Stack Developer",
        company: "Padhai help pvt. ltd.",
        type: "Internship",
        location: "Remote",
        date: "May 2023 - May 2023",
        logo: PadhaiHelp,
        description: [
            " Developed responsive front-end interfaces with HTML, CSS, and React.js",
            "Implemented efficient back-end solutions using Node.js",
            "Managed MongoDB database for optimized storage and retrieval."
        ]
    },

]

