import React, { useEffect, useContext } from 'react';
import NavBar from '../components/NavBar';
import { education } from '../Data/education';
import EducationItem from '../components/Education/EducationItem';
import Loader from '../components/Loader/Loader';
import loaderContext from '../context/loaderContext';

const Education = () => {
  const context = useContext(loaderContext);
  const { loading, setLoading } = context;

  useEffect(() => {
    document.title = "Education | Utkarsh Saini";
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, [setLoading]);

  return (
    <div className="container-fluid d-flex flex-column align-items-stretch" style={{ padding: "1rem" }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="navbar-container mt-2 ms-4" style={{ fontSize: "1.5rem" }}>
            <NavBar />
          </div>
        
          <div className="container mt-4 p-8">
            <div className="row px-4 justify-content-center">
              <div className="col-12 col-md-8">
                <h2 className="text-left">Education</h2>
                <div className="text-justify">
                  {education.map((edu, i) => (
                    <div key={edu.id} className="mb-2">
                      <EducationItem
                        logo={edu.logo}
                        name={edu.name}
                        degree={edu.degree}
                        stream={edu.stream}
                        date={edu.date}
                        location={edu.location}
                        grade={edu.grade}
                        delay={(i + 1) / 3}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Education;
