import React, { useEffect, useContext } from 'react';
import NavBar from '../components/NavBar';
import Loader from '../components/Loader/Loader';
import loaderContext from '../context/loaderContext';
import SkillDiv from '../components/Skills/SkillDiv';
import { programming_languages, technologies_frameworks, developer_tools, soft_skills, platforms } from "../Data/skills";

const Skills = () => {
    const context = useContext(loaderContext);
    const { loading, setLoading } = context;

    useEffect(() => {
        document.title = "Skills | Utkarsh Saini";
        setTimeout(() => {
            setLoading(false);
        }, 4000);
    }, [setLoading]);

    return (
        <div className="container-fluid d-flex flex-column align-items-stretch" style={{ padding: "1rem" }}>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="navbar-container mt-2 ms-4" style={{ fontSize: "1.5rem" }}>
                        <NavBar />
                    </div>

                    <div className="container mt-4 p-8">
                        <div className="row px-4 justify-content-center">
                            <div className="col-12 col-md-8">
                                <h2 className="text-left">Skills</h2>
                                <div className="text-justify">
                                    <div className='mb-2'>
                                        <SkillDiv title="Programming Skills" dataelement={programming_languages} />
                                    </div>
                                    <div className='mb-2'>
                                        <SkillDiv title="Technology Frameworks" dataelement={technologies_frameworks} />
                                    </div>
                                    <div className='mb-2'>
                                        <SkillDiv title="Developer Tools" dataelement={developer_tools} />
                                    </div>
                                    <div className='mb-2'>
                                        <SkillDiv title="Soft Skills" dataelement={soft_skills} />
                                    </div>
                                    <div className='mb-2'>
                                        <SkillDiv title="Platforms" dataelement={platforms} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Skills;
