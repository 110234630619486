import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiSend } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactButton = () => {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    invalidEmail: false,
    message: false,
  });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setName("");
    setEmail("");
    setMessage("");
    setErrors({
      name: false,
      email: false,
      invalidEmail: false,
      message: false,
    });
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrors({ ...errors, name: false });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors({ ...errors, email: false, invalidEmail: false });
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    setErrors({ ...errors, message: false });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Check for validation errors
    if (!name.trim()) {
      setErrors({ ...errors, name: true });
      return;
    }
    if (!email.trim()) {
      setErrors({ ...errors, email: true });
      return;
    }
    if (!isValidEmail(email)) {
      setErrors({ ...errors, invalidEmail: true });
      return;
    }
    if (!message.trim()) {
      setErrors({ ...errors, message: true });
      return;
    }

    setLoading(true);

    try {
      const response = await fetch("https://portfolio-backend-kappa-three.vercel.app/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          message: message,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send message. Please try again.");
      }

      const data = await response.json();
      if (data.message) {
        toast.success(data.message, { position: "top-right", autoClose: 5000 });
        handleClose();
      } else {
        throw new Error("Failed to send message. Please try again.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error(error.message, { position: "top-right", autoClose: 5000 });
    }

    setLoading(false);
  };

  const isValidEmail = (email) => {
    // Very basic email validation
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <>
      <ToastContainer />

      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        size="sm"
        dialogClassName="modal-no-border"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Send me a message</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <form onSubmit={handleFormSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
                id="name"
                value={name}
                onChange={handleNameChange}
              />
              {errors.name && (
                <div className="invalid-feedback">Name is required.</div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className={`form-control ${
                  errors.email || errors.invalidEmail ? "is-invalid" : ""
                }`}
                id="email"
                value={email}
                onChange={handleEmailChange}
              />
              {(errors.email || errors.invalidEmail) && (
                <div className="invalid-feedback">
                  {errors.email ? "Email is required." : "Enter a valid email."}
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                className={`form-control ${errors.message ? "is-invalid" : ""}`}
                id="message"
                rows="3"
                value={message}
                onChange={handleMessageChange}
              ></textarea>
              {errors.message && (
                <div className="invalid-feedback">Message is required.</div>
              )}
            </div>
            <div className="text-end">
              <Button
                variant="outline-success"
                type="submit"
                onClick={handleFormSubmit}
                disabled={loading}
              >
                {loading ? "Sending Message..." : "Send Message"} <FiSend />
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Button
        variant="primary"
        onClick={() => setShowModal(true)}
        className="home-button mb-3"
      >
        <FiSend /> Contact
      </Button>
    </>
  );
};

export default ContactButton;
