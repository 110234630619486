import React from "react";
import { motion } from 'framer-motion';
import SkillButton from "./SkillButton";

const SkillDiv = (props) => {
  return (
    <div>
      <div className="col-lg-10 col-md-12 col-sm-12">
        <motion.div
          initial={{ y: "100%", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", duration: 0.33 }}
        >
          <motion.div whileHover={{ scale: 1.05 }}>
            <div className="border rounded p-4">
              <p className="mb-3 fw-bold" style={{ fontSize: "1.2rem" }}>{props.title}</p>
              <div className="d-flex flex-wrap">
                {props.dataelement.map((el) => (
                  <SkillButton key={el.id} text={el.text} icon={el.icon} />
                ))}
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};


export default SkillDiv;
