import vit from "../images/vit.png"
import jvp from "../images/jvp.png"
export const education = [
    {
        id: 0,
        logo: vit,
        name: "Vellore Institute of Technology",
        degree: "B.Tech",
        stream: "Computer Science",
        date: "2020 - Present",
        location: "Vellore, Tamil Nadu",
        grade: "8.77 CGPA"
    },
    {
        id: 1,
        logo: jvp,
        name: "Jyoti VidyaPeeth Sr. Sec. School",
        degree: "12th Board",
        stream: "PCM",
        date: "2018 - 2019",
        location: "Jhunjhunu, Rajasthan",
        grade: "86.2 %"
    },
    {
        id: 2,
        logo: jvp,
        name: "Jyoti VidyaPeeth Sr. Sec. School",
        degree: "10th Board",
        stream: "All Compulsory Subjects",
        date: "2016 - 2017",
        location: "Jhunjhunu, Rajasthan",
        grade: "10 CGPA"
    }
]