import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import Skills from './pages/Skills';
import Home from './pages/Home';
import About from './pages/About';
import Education from './pages/Education';
import Projects from './pages/Projects';
import Experience from './pages/Experience';
import Certifications from './pages/Certifications';
import LoaderState from './context/LoaderState';
import PageNotFound from './pages/PageNotFound';
import ParticleComponent from './components/Particle/ParticleComponent';
import './App.css';


ReactDOM.render(
    <React.StrictMode>
        <ParticleComponent />
        <LoaderState>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/skills" element={<Skills />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/experience" element={<Experience />} />
                    <Route path="/education" element={<Education />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/certifications" element={<Certifications />} />

                    {/* Page Not Found */}
                    <Route path='*' element={<PageNotFound />} />
                </Routes>
            </BrowserRouter>
        </LoaderState>
    </React.StrictMode>,
    document.getElementById('root')
);
