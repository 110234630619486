import { SiLeetcode, SiGeeksforgeeks } from "react-icons/si"
import { FiGithub, FiLinkedin, FiInstagram, FiMail } from "react-icons/fi"
const socials = [
    {
        id: 0,
        site: "Linkedin",
        url: "https://www.linkedin.com/in/utkarsh-saini-884012218/",
        icon: <FiLinkedin />,
        color: "#0077B5"
    },
    {
        id: 1,
        site: "Github",
        url: "https://github.com/Utkarshsaini01",
        icon: <FiGithub />,
        color:"#171515"
    },
    {
        id: 2,
        site: "Instagram",
        url: "https://instagram.com/beingutkarshsaini",
        icon: <FiInstagram />,
        color:"#F70269"
    },
    {
        id: 3,
        site: "Mail",
        url: "mailto:sainiutkarsh01@gmail.com",
        icon: <FiMail />,
        color:"#EA4335"
    },
    {
        id: 4,
        site: "Leetcode",
        url: "https://leetcode.com/sainiutkarsh01/",
        icon: <SiLeetcode />,
        color:"#E49E3E"
    },
    {
        id: 5,
        site: "Geeks For Geeks",
        url: "https://auth.geeksforgeeks.org/user/sainiutkarsh01",
        icon: <SiGeeksforgeeks />,
        color:"#2D8642"
    }
]

export default socials;