import React from "react";
import { FiExternalLink, FiGithub } from "react-icons/fi";
import { motion } from "framer-motion";

const Project = (props) => {
  return (
    <motion.div
      initial={{ y: "100%", opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        type: "spring",
        duration: props.delay || 1,
      }}
      className="mb-0"
    >
      <motion.div whileHover={{ scale: 1.05 }}>
        <div className="p-3 border rounded">
          <div className="row">
            <div className="col-lg-10 col-md-12 col-sm-12 d-flex text-start align-items-center">
              <div className="me-3">{props.emoji}</div>
              <div>
                <p className="fw-bold mb-0 title">{props.name}</p>
                <p className="mb-0 text-muted description">
                  {props.description}
                </p>
              </div>
            </div>

            <div className="col-lg-2 col-md-12 col-sm-12 text-left pt-2">
              <div className="d-flex align-items-center">
                {props.website && (
                  <div className="col-md-6 col-sm-6 col-6 me-2">
                    <button
                      type="button"
                      className="btn btn-outline-secondary w-100"
                    >
                      <a
                        href={props.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none text-reset"
                      >
                        <FiExternalLink />
                      </a>
                    </button>
                  </div>
                )}
                <div
                  className={`text-end col-md-${
                    props.website ? "6" : "12"
                  } col-sm-${props.website ? "6" : "12"} col-${
                    props.website ? "6" : "12"
                  }`}
                >
                  <button
                    type="button"
                    className="btn btn-outline-secondary w-100"
                  >
                    <a
                      href={props.github}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none text-reset"
                    >
                      <FiGithub />
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Project;
