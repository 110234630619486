import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import { FiEye } from "react-icons/fi";
import { FiCalendar } from "react-icons/fi";

const Certificate = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const imgRef = useRef(null);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleImageLoad = () => {
      const img = imgRef.current;
      if (img) {
        const parentWidth = img.parentElement.offsetWidth;
        const parentHeight = img.parentElement.offsetHeight;
        const { naturalWidth, naturalHeight } = img;
        if (naturalWidth / parentWidth > naturalHeight / parentHeight) {
          img.width = parentWidth * 0.7; // Adjusted width to 70% of the parent width
          img.height = (naturalHeight * parentWidth * 0.7) / naturalWidth;
        } else {
          img.height = parentHeight * 0.7; // Adjusted height to 70% of the parent height
          img.width = (naturalWidth * parentHeight * 0.7) / naturalHeight;
        }
      }
    };

    handleImageLoad();
    window.addEventListener("resize", handleImageLoad);

    return () => {
      window.removeEventListener("resize", handleImageLoad);
    };
  }, []);

  return (
    <>
      <Modal show={isOpen} onHide={toggleModal} size="md" centered>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <div className="image-container">
            <img
              ref={imgRef}
              src={props.certificate}
              alt="Certificate"
              style={{ maxWidth: "100%", maxHeight: "100%", padding: 0 }}
            />
          </div>
        </Modal.Body>
      </Modal>
      <motion.div
        initial={{ y: "100%", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          type: "spring",
          duration: props.delay,
        }}
      >
        <motion.div whileHover={{ scale: 1.05 }}>
          <div className="p-3 border rounded">
            <div className="row m-0">
              <div className="col-lg-10 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="me-3">
                  <a
                    href={props.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="certi-heading"
                  >
                    {props.title}
                  </a>
                  <p className="text-semi-bold mb-1">{props.description}</p>
                  <p className="text-muted d-flex align-items-center mb-0">
                    <FiCalendar className="me-1" />
                    {props.date}
                  </p>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 text-left pt-2 d-flex justify-content-center">
                <div className="d-flex align-items-center">
                  <img
                    src={props.certificate}
                    alt="Certificate"
                    className="d-none d-lg-block mb-0"
                    style={{
                      cursor: "pointer",
                      maxHeight: "100%",
                      maxWidth: "100%",
                    }}
                    onClick={toggleModal}
                  />
                  <button
                    className="btn btn-outline-secondary d-block d-lg-none mb-0"
                    onClick={toggleModal}
                    style={{ cursor: "pointer" }}
                  >
                    <FiEye className="me-2" />
                    View Certificate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Certificate;
