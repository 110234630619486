import React, { useEffect, useContext } from "react";
import NavBar from "../components/NavBar";
import Loader from "../components/Loader/Loader";
import about from "../Data/about";
import loaderContext from "../context/loaderContext";

const About = () => {
  const context = useContext(loaderContext);
  const { loading, setLoading } = context;

  useEffect(() => {
    document.title = "About | Utkarsh Saini";
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, [setLoading]);

  return (
    <div style={{ display: "flex", alignItems: "stretch", flexDirection: "column", padding: "1rem" }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="navbar-container mt-2 ms-4" style={{ fontSize: "1.5rem" }}>
            <NavBar />
          </div>
        
          <div className="container mt-4 p-8" style={{ alignSelf: "center" }}>
            <div className="row justify-content-center">
              <div className="col-12 col-md-8">
                <h2 className="text-left">About</h2>
                <div className="text-justify" style={{ lineHeight: "1.8" }}>
                  {about.map((a, index) => (
                    <p key={index}>
                      {a}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default About;
