import React from "react";

const SkillButton = ({ text, icon }) => {
  return (
    <button
      className="btn btn-outline-secondary btn-sm rounded me-2 mb-2 d-flex align-items-center"
    >
      <span className="me-2">{icon}</span>
      {text}
    </button>
  );
};

export default SkillButton;
