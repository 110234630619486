import { useState } from "react";
import LoaderContext from "./loaderContext";

const LoaderState = (props) => {
    const [loading, setLoading] = useState(true)

    return (
        <LoaderContext.Provider value={{ loading, setLoading }}>
            {props.children}
        </LoaderContext.Provider>
    )
}

export default LoaderState;