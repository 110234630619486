import React, { useState, useEffect, useRef } from "react";
import { FiMenu, FiHome, FiUser, FiBookOpen, FiTool, FiCode, FiX, FiBriefcase, FiAward } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);
  const location = useLocation();

  const MenuItemButton = ({ name, icon, link, closeMenu }) => {
    if (!link) {
      console.error(`Missing link for menu item: ${name}`);
      return null;
    }
  
    return (
      <Link to={link} className="nav-link text-dark" onClick={closeMenu}>
        <span className="px-2">{icon}</span>
        <span className="pe-3">{name}</span>
      </Link>
    );
  };

  const toggleModal = (event) => {
    event.stopPropagation(); // Prevent the click event from bubbling up
    setIsOpen(!isOpen);
  };
  

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleLocationChange = () => {
      setIsOpen(false);
    };

    window.addEventListener('popstate', handleLocationChange);

    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, [location]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="position-relative">
      <button className="navbar-toggler" type="button" onClick={toggleModal}>
        {isOpen ? <FiX /> : <FiMenu />}
      </button>
      {isOpen && (
        <div
          ref={modalRef}
          className="position-absolute top-100 start-0 shadow-lg rounded"
          style={{
            zIndex: 999,
            backgroundColor: "#f5f5f5",
          }}
        >
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <MenuItemButton name="Home" icon={<FiHome />} link="/" closeMenu={closeMenu} />
            </li>
            <li className="nav-item">
              <MenuItemButton name="About" icon={<FiUser />} link="/about" closeMenu={closeMenu} />
            </li>
            <li className="nav-item">
              <MenuItemButton name="Education" icon={<FiBookOpen />} link="/education" closeMenu={closeMenu} />
            </li>
            <li className="nav-item">
              <MenuItemButton name="Experience" icon={<FiBriefcase />} link="/experience" closeMenu={closeMenu} />
            </li>
            <li className="nav-item">
              <MenuItemButton name="Skills" icon={<FiTool />} link="/skills" closeMenu={closeMenu} />
            </li>
            <li className="nav-item">
              <MenuItemButton name="Projects" icon={<FiCode />} link="/projects" closeMenu={closeMenu} />
            </li>
            {/* <li className="nav-item">
              <MenuItemButton name="Volunteering" icon={<FiSmile />} link="/volunteering" closeMenu={closeMenu} />
            </li> */}
            <li className="nav-item">
              <MenuItemButton name="Certifications" icon={<FiAward />} link="/certifications" closeMenu={closeMenu} />
            </li>

          </ul>
        </div>
      )}
    </div>
  );
};

export default NavBar;
