import React, { useState, useEffect } from "react";
import socials from "../../Data/social";

const SocialMedia = () => {
  const [hoveredId, setHoveredId] = useState(null); 

  useEffect(() => {
    const handleMouseEnter = (id) => {
      setHoveredId(id); 
    };

    const handleMouseLeave = () => {
      setHoveredId(null); 
    };

    const addEventListeners = () => {
      socials.forEach((social) => {
        const element = document.getElementById(`social-icon-${social.id}`);
        if (element) {
          element.addEventListener("mouseenter", () => handleMouseEnter(social.id));
          element.addEventListener("mouseleave", handleMouseLeave);
        }
      });
    };

    const removeEventListeners = () => {
      socials.forEach((social) => {
        const element = document.getElementById(`social-icon-${social.id}`);
        if (element) {
          element.removeEventListener("mouseenter", () => handleMouseEnter(social.id));
          element.removeEventListener("mouseleave", handleMouseLeave);
        }
      });
    };

    addEventListeners();

    return () => {
      removeEventListeners();
    };
  }, []);

  return (
    <div className="d-flex flex-wrap justify-content-center">
      {socials.map((social) => (
        <div className="flex-grow-1" key={social.id}>
          <a
            href={social.url}
            target="_blank"
            rel="noopener noreferrer" 
            className="d-inline-block social-icon"
            id={`social-icon-${social.id}`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: hoveredId === social.id ? "1.8rem" : "1.5rem",
              color: hoveredId === social.id ? social.color : "#000",
              transition: "font-size 0.3s",
            }}
          >
            {social.icon}
          </a>
        </div>
      ))}
    </div>
  );
};

export default SocialMedia;
